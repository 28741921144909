<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="已出账单" name="first">
        <el-form ref="form" :model="form" label-width="130px" style="display: flex" :rules="rules">
          <div class="el-lt" style="width: 85%;">
            <el-row :span="24" class="row-input" :class="{ 'show-row': showRow }">
              <el-col :span="8">
                <el-form-item label="采购单号" :class="$i18n.locale">
                  <el-input v-model="form.purchaseOrderCode" placeholder="换行符或者逗号分隔" type="textarea" autosize />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="生成日期(起)" :class="$i18n.locale">
                  <el-date-picker
                    v-model="form.createTimeStart"
                    type="date"
                    style="width:100%;"
                    :picker-options="createTimeStartOptions"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="生成日期(止)" :class="$i18n.locale">
                  <el-date-picker
                    v-model="form.createTimeEnd"
                    type="date"
                    style="width:100%;"
                    :picker-options="createTimeEndOptions"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="暂估应付单号" :class="$i18n.locale">
                  <el-input v-model="form.estimationPayableCode" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="结算主体" :class="$i18n.locale">
                  <el-select
                    v-model="form.cargoOwnerCode"
                    style="width:100%;"
                    value-key="id"
                    placeholder="请选择"
                    filterable
                    clearable
                  >
                    <el-option
                      v-for="item in cargoOwnerNameList"
                      :key="item.cargoOwnerName"
                      :label="item.cargoOwnerName"
                      :value="item.cargoOwnerCode"
                    />
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="8">
                <el-form-item label="付款类型" :class="$i18n.locale">
                  <el-select v-model="form.paymentType" value-key="id" clearable filterable style="width:100%;">
                    <el-option
                      v-for="item in paymentTypeList"
                      :key="item.val"
                      :label="item.label"
                      :value="item.val"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="结算方式" :class="$i18n.locale">
                  <el-select v-model="form.settlementMethod" value-key="id" clearable filterable style="width:100%;">
                    <el-option
                      v-for="item in _getAllCommodityDict('FIN_SETTLEMENT_METHOD')"
                      :key="item.val"
                      :label="item.label"
                      :value="item.val"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="供应商" :class="$i18n.locale">
                  <el-select
                    v-model="form.vendorId"
                    style="width:100%;"
                    filterable
                    clearable
                    :placeholder="$t('page.selectPlaceholder')"
                  >
                    <el-option v-for="item in vendorOptions" :key="item.id" :label="item.vendorName" :value="item.id" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="收货单号" :class="$i18n.locale">
                  <el-input v-model="form.collectCode" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="来源单号" :class="$i18n.locale">
                  <el-input v-model="form.sourceCode" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="结算状态" :class="$i18n.locale">
                  <el-select v-model="form.settlementStatus" value-key="id" clearable filterable style="width:100%;">
                    <el-option
                      v-for="item in _getAllCommodityDict('FIN_SETTLEMENT_STATUS')"
                      :key="item.val"
                      :label="item.label"
                      :value="item.val"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="对账状态" :class="$i18n.locale">
                  <el-select v-model="form.reconcileStatus" style="width:100%;" value-key="id" clearable filterable>
                    <el-option
                      v-for="item in _getAllCommodityDict('FIN_RECONCILE_STATUS')"
                      :key="item.val"
                      :label="item.label"
                      :value="item.val"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="账单截止日期（起）" label-width="140px">
                  <el-date-picker
                    v-model="form.accountPeriodDateStart"
                    type="date"
                    style="width:100%;"
                    :picker-options="accountPeriodDateStartOptions"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="账单截止日期（止）" label-width="140px">
                  <el-date-picker
                    v-model="form.accountPeriodDateEnd"
                    type="date"
                    style="width:100%;"
                    :picker-options="accountPeriodDateEndOptions"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :span="24" class="mb-3">
              <el-col :span="24">
                <!-- 手工勾选已对账、待结算状态单据发起请款，可多选 -->
                <el-button
                  v-permission="'handleSettlement'"
                  type="primary"
                  :loading="settleLoading"
                  @click="handleSettlement"
                >结算</el-button>
                <!-- 差异确认中 -->
                <el-button v-permission="'handleDiff'" type="primary" @click="handleDiff">差异确认</el-button>
                <el-button v-permission="'updateDate'" type="primary" @click="updateDate">账期截止日调整</el-button>
                <ImportButton export-key="fin_out_bill" :params="form" />
              </el-col>
            </el-row>
          </div>
          <el-row class="el-rt" style="width:25%">
            <el-col class="row-center">
              <el-button type="primary" :loading="queryLoading" @click="queryClick()">{{
                $t("page.search")
              }}</el-button>
              <el-button @click="resetClick()">{{ $t("page.reset") }}</el-button>
              <el-button type="text" @click="showRow=!showRow">{{
                showRow ? $t("page.hide") : $t("page.expand")
              }}</el-button>
            </el-col>
          </el-row>
        </el-form>
        <el-table
          ref="haveBillDataRef"
          :data="haveBillData"
          :header-cell-style="{ background: '#fafafa' }"
          tooltip-effect="dark"
          max-height="600px"
          show-summary
          :summary-method="getSummaries"
          @selection-change="selectionChange"
        >
          <el-table-column type="selection" width="60" align="center" :selectable="selectInit" />
          <el-table-column :label="$t('page.No')" type="index" width="80" align="center" sortable>
            <template slot-scope="scope">
              {{ (pager1.current - 1)*pager1.size + scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="estimationPayableCode" label="暂估应付单" min-width="170" />
          <el-table-column prop="cargoOwnerName" label="结算主体" />
          <el-table-column prop="settlementMethodI18" label="结算方式">
            <template slot-scope="scope">
              {{ getAuditTypeLabel('FIN_SETTLEMENT_METHOD',scope.row.settlementMethod) }}
            </template>
          </el-table-column>
          <el-table-column prop="vendorName" label="供应商" />
          <el-table-column prop="purchaseOrderCode" label="采购订单" min-width="160" />
          <el-table-column prop="purchaseStatus" label="采购单状态" width="90">
            <template slot-scope="scope">
              {{ getAuditTypeLabel('PURCHASE_ORDER_STATUS', scope.row.purchaseStatus) }}
            </template>
          </el-table-column>
          <el-table-column prop="collectCode" label="收货单" min-width="185">
            <template slot-scope="scope">
              <div v-for="(item,index) in scope.row.collectCode&&scope.row.collectCode.split(',')" :key="index">
                <span>{{ item }}</span>
              </div>
            </template>
          </el-table-column>
          <!-- deitaldialog -->
          <el-table-column prop="sourceCode" label="来源单据" min-width="180">
            <template slot-scope="scope">
              <div v-for="(item,index) in scope.row.sourceCode&&scope.row.sourceCode.split(',')" :key="index">
                <el-button type="text" :loading="scope.row.loading" @click="showDialog(scope.row,'sourceCode')">
                  {{ item }}</el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="paymentType" label="付款类型">
            <template slot-scope="scope">
              {{ getAuditTypeLabel('FIN_PAYMENT_TYPE', scope.row.paymentType) }}
            </template>
          </el-table-column>
          <el-table-column prop="totalPrice" label="单据金额" width="130" />
          <el-table-column prop="paymentRatio" label="付款比例%" width="90" />
          <el-table-column prop="paymentAmount" label="付款金额" width="130" />
          <el-table-column prop="currency" label="结算币种">
            <template slot-scope="scope">
              {{ getAuditTypeLabel('CURRENCY_TYPE', scope.row.currency) }}
            </template>
          </el-table-column>
          <el-table-column prop="toWarehouseDate" label="到仓日期" min-width="150" />
          <el-table-column prop="createTime" label="生成时间" min-width="150" />
          <el-table-column prop="shelfDate" label="上架日期" min-width="150">
            <template slot-scope="scope">
              {{ scope.row.shelfDate && scope.row.shelfDate.substring(0,10) }}
            </template>
          </el-table-column>
          <el-table-column prop="accountPeriodTime" label="账期截止日期" width="110" />
          <el-table-column label="对账链接">
            <template slot-scope="scope">
              <el-button
                v-if="(scope.row.paymentType !== 11 && scope.row.reconcileSign)||(scope.row.paymentType === 11 && scope.row.reconcileStatus === 1)"
                type="text"
                @click="handleDetail(scope.row)"
              >查看</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="reconcileStatusI18" label="对账状态" />
          <!-- <el-table-column prop="reconcileStatus" label="对账状态">
            <template slot-scope="scope">
              {{ getAuditTypeLabel('FIN_RECONCILE_STATUS', scope.row.reconcileStatus) }}
            </template>
          </el-table-column> -->
          <el-table-column prop="reconcileTime" label="对账时间" />
          <el-table-column prop="settlementStatus" label="结算状态">
            <template slot-scope="scope">
              {{ getAuditTypeLabel('FIN_SETTLEMENT_STATUS', scope.row.settlementStatus) }}
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <Paging :pager="pager1" end @pagination="pagerUpdate" />
        </div>
      </el-tab-pane>
      <el-tab-pane label="未出账单" name="second">
        <el-form ref="noForm" :model="noForm" label-width="130px" style="display: flex" :rules="noRules">
          <div class="el-lt" style="width: 85%">
            <el-row :span="24" class="row-input" :class="{ 'show-row': showRow }">
              <el-col :span="8">
                <el-form-item label="出货日期（起）" :class="$i18n.locale">
                  <el-date-picker
                    v-model="noForm.shippingDateStart"
                    type="date"
                    style="width:100%;"
                    :picker-options="shippingDateStartOptions"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="出货日期（止）" :class="$i18n.locale">
                  <el-date-picker
                    v-model="noForm.shippingDateEnd"
                    type="date"
                    style="width:100%;"
                    :picker-options="shippingDateEndOptions"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="结算主体" :class="$i18n.locale">
                  <el-select v-model="noForm.cargoOwnerCode" value-key="id" clearable filterable style="width:100%;">
                    <el-option
                      v-for="item in cargoOwnerNameList"
                      :key="item.cargoOwnerName"
                      :label="item.cargoOwnerName"
                      :value="item.cargoOwnerCode"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="结算方式" :class="$i18n.locale">
                  <el-select v-model="noForm.settlementMethod" value-key="id" clearable filterable style="width:100%;">
                    <el-option
                      v-for="item in _getAllCommodityDict('FIN_SETTLEMENT_METHOD')"
                      :key="item.val"
                      :label="item.label"
                      :value="item.val"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="供应商" :class="$i18n.locale">
                  <el-select
                    v-model="noForm.vendorId"
                    style="width:100%;"
                    filterable
                    clearable
                    :placeholder="$t('page.selectPlaceholder')"
                  >
                    <el-option v-for="item in vendorOptions" :key="item.id" :label="item.vendorName" :value="item.id" />
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="8">
                <el-form-item label="采购单号" :class="$i18n.locale">
                  <el-input v-model="noForm.purchaseOrderCode" />
                </el-form-item>
              </el-col>

              <el-col :span="8">
                <el-form-item label="采购单状态" :class="$i18n.locale">
                  <el-select v-model="noForm.purchaseStatus" value-key="id" clearable filterable style="width:100%;">
                    <el-option
                      v-for="item in _getAllCommodityDict('PURCHASE_ORDER_STATUS')"
                      :key="item.val"
                      :label="item.label"
                      :value="item.val"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="下单日期（起）" :class="$i18n.locale">
                  <el-date-picker
                    v-model="noForm.orderDateStart"
                    type="date"
                    style="width:100%;"
                    :picker-options="orderDateStartOptions"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="下单日期（止）" :class="$i18n.locale">
                  <el-date-picker
                    v-model="noForm.orderDateEnd"
                    type="date"
                    style="width:100%;"
                    :picker-options="orderDateEndOptions"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Style" :class="$i18n.locale">
                  <el-select
                    v-model="noForm.style"
                    style="width:100%;"
                    filterable
                    clearable
                    :placeholder="$t('page.selectPlaceholder')"
                  >
                    <el-option
                      v-for="item in styleOptions"
                      :key="item.id"
                      :label="item.styleName"
                      :value="item.styleName"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :span="24" class="mb-3">
              <el-col :span="24">
                <ImportButton export-key="fin_not_out_bill" :params="noForm" />
                <el-button v-permission="'out'" type="primary" @click="showDialgOprate('out')">出货付款</el-button>
                <el-button v-permission="'special'" type="primary" @click="showDialgOprate('special')">特殊付款</el-button>
              </el-col>
            </el-row>
          </div>
          <el-row class="el-rt" style="width: 25%">
            <el-col class="row-center">
              <el-button type="primary" :loading="queryLoading" @click="queryClick()">{{
                $t("page.search")
              }}</el-button>
              <el-button @click="resetClick()">{{ $t("page.reset") }}</el-button>
              <el-button type="text" @click="showRow=!showRow">{{
                showRow ? $t("page.hide") : $t("page.expand")
              }}</el-button>
            </el-col>
          </el-row>
        </el-form>
        <el-table
          ref="NoBillDataRef"
          :data="NoBillData"
          :loading="tableLoading"
          :header-cell-style="{ background: '#fafafa' }"
          tooltip-effect="dark"
          max-height="600px"
          show-summary
          :summary-method="getSummaries"
          @selection-change="selectionChange"
        >
          <el-table-column type="selection" width="60" align="center" />
          <el-table-column :label="$t('page.No')" type="index" width="80" align="center" sortable>
            <template slot-scope="scope">
              {{ (pager2.current - 1)*pager2.size + scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="cargoOwnerName" label="货主" />
          <el-table-column prop="vendorName" label="供应商" />
          <el-table-column prop="settlementMethod" label="结算方式">
            <template slot-scope="scope">
              {{ getAuditTypeLabel('FIN_SETTLEMENT_METHOD',scope.row.settlementMethod) }}
            </template>
          </el-table-column>
          <el-table-column prop="purchaseOrderCode" label="采购订单" min-width="150">
            <template slot-scope="scope">
              <el-button type="text" :loading="scope.row.loading" @click="showDialog(scope.row,'purchaseOrderCode')">
                {{ scope.row.purchaseOrderCode }}</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="purchaseStatus" label="采购单状态" width="90">
            <template slot-scope="scope">
              {{ getAuditTypeLabel('PURCHASE_ORDER_STATUS', scope.row.purchaseStatus) }}
            </template>
          </el-table-column>
          <el-table-column prop="purchasePairs" label="采购数量" />
          <el-table-column prop="purchaseAmount" label="采购金额" width="130" />
          <el-table-column prop="surplusPairs" label="剩余数量" width="130" />
          <el-table-column prop="surplusAmount" label="剩余金额" width="130" />
          <el-table-column prop="currency" label="结算币种">
            <template slot-scope="scope">
              {{ getAuditTypeLabel('CURRENCY_TYPE', scope.row.currency) }}
            </template>
          </el-table-column>
          <el-table-column prop="style" label="Style" />
          <el-table-column prop="orderDate" label="下单日期" width="100" />
          <el-table-column prop="shippingDate" label="出货日期" width="100" />
        </el-table>
        <div class="block">
          <Paging :pager="pager2" end @pagination="pagerUpdateNoForm" />
        </div>
      </el-tab-pane>
    </el-tabs>
    <detailDialog v-model="dialogVisible" :row="row" @refresh="queryClick" />
    <payDialog v-model="paydialogVisible" :row="row" :form="form" @refresh="queryClick" />
    <indemnityDialog v-model="resDialogVisible" :row="row" />
    <!-- 日期调整updateAccountPeriodTime -->
    <el-dialog title="账期截止日期调整" :visible.sync="updateDialogVisible" width="500px" center>
      <el-form ref="updateForm" :model="updateForm">
        <el-form-item label="账期截止日期" prop="accountPeriodTime" :rules="{ required: true, message: '必填'}">
          <el-date-picker
            v-model="updateForm.accountPeriodTime"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
            :picker-options="accountPeriodOptions"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="updateDialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="updateLoading" @click="_updateAccountPeriodTime">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { sourceCodeColumns2, sourceCodeColumns1, sourceCodeColumns3, diffColumns } from '@/constant/tablecolumns'
import { specialPaymentValid, notOutBillDetail, shippingPaymentView, outBillPage, notOutBillPage, updateAccountPeriodTime, outBillDetail, settlement, indemnityOutBillView } from '@/api/vendorPurchase-api.js'
import { getcargoownerList, queryStyleList, queryVendorList } from '@/api/scm-api'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import Paging from '@/components/Pagination'
import payDialog from '../components/payDialog'
import detailDialog from '../components/detailDialog'
import indemnityDialog from '../components/indemnityDialog'
import ImportButton from '@/components/ImportButton'
import { FloatAdd, FloatSub } from '@/utils/acc.js'
import { preText } from '@/utils/index.js'
import { returnLabel, returnValue, reconcileLabel, reconcileValue, labelDiff, valueDiff, poValue, poLabel } from '@/constant/formlabel'
export default {
  components: { detailDialog, Paging, payDialog, ImportButton, indemnityDialog },
  mixins: [commodityInfoDict],
  data() {
    return {
      returnLabel, returnValue, reconcileLabel, reconcileValue, labelDiff, valueDiff, poValue, poLabel,
      settleLoading: false,
      tableLoading: false,
      updateLoading: false,
      updateForm: {
        accountPeriodTime: ''
      },
      updateDialogVisible: false,
      createTimeStartOptions: {
        disabledDate: (time) => {
          if (this.form.createTimeEnd) {
            return time.getTime() > new Date(this.form.createTimeEnd).getTime()
          }
        }
      },
      createTimeEndOptions: {
        disabledDate: (time) => {
          if (this.form.createTimeStart) {
            return time.getTime() < new Date(this.form.createTimeStart).getTime()
          }
        }
      },
      accountPeriodDateStartOptions: {
        disabledDate: (time) => {
          if (this.form.accountPeriodDateEnd) {
            return time.getTime() > new Date(this.form.accountPeriodDateEnd).getTime()
          }
        }
      },
      accountPeriodDateEndOptions: {
        disabledDate: (time) => {
          if (this.form.accountPeriodDateStart) {
            return time.getTime() < new Date(this.form.accountPeriodDateStart).getTime()
          }
        }
      },
      shippingDateStartOptions: {
        disabledDate: (time) => {
          if (this.noForm.shippingDateEnd) {
            return time.getTime() > new Date(this.noForm.shippingDateEnd).getTime()
          }
        }
      },
      shippingDateEndOptions: {
        disabledDate: (time) => {
          if (this.noForm.shippingDateStart) {
            return time.getTime() < new Date(this.noForm.shippingDateStart).getTime()
          }
        }
      },
      orderDateStartOptions: {
        disabledDate: (time) => {
          if (this.noForm.orderDateEnd) {
            return time.getTime() > new Date(this.noForm.orderDateEnd).getTime()
          }
        }
      },
      orderDateEndOptions: {
        disabledDate: (time) => {
          if (this.noForm.orderDateStart) {
            return time.getTime() < new Date(this.noForm.orderDateStart).getTime()
          }
        }
      },
      accountPeriodOptions: {
        disabledDate: (time) => {
          if (this.time) {
            return time.getTime() < new Date(this.time).getTime()
          }
        }
      },
      time: '',
      styleOptions: [],
      cargoOwnerNameList: [],
      pager1: {
        current: 1,
        size: 20,
        total: 0
      },
      pager2: {
        current: 1,
        size: 20,
        total: 0
      },
      NoBillData: [],
      noForm: {},
      row: {
        title: ''
      },
      dialogData: [],
      title: '',
      paydialogVisible: false,
      dialogVisible: false,
      queryLoading: false,
      haveBillData: [],
      activeName: 'first',
      form: {},
      rules: {},
      noRules: {},
      vendorOptions: [],
      showRow: false,
      multipleSelection: [],
      dialogTitle: [{
        label: '收货价格差异详情', value: 9
      }, {
        label: '对账差异详情', value: 7
      }, {
        label: '退货价格差异详情', value: 10
      }, {
        label: '退货差异详情', value: 8
      }, {
        label: '采购退货单详情', value: 5
      }],
      dialogColumns: [
        { value: [7, 8, 9, 10], columns: diffColumns },
        { value: [3], columns: sourceCodeColumns1 },
        { value: [5], columns: sourceCodeColumns2 }
      ],
      resDialogVisible: false
    }
  },
  computed: {
    queryParams() {
      if (this.activeName === 'first') {
        return Object.assign({}, this.form, this.pager1, { purchaseOrderCode: preText(this.form.purchaseOrderCode) || '' })
      } else {
        return Object.assign({}, this.noForm, this.pager2)
      }
    },
    paymentTypeList() {
      return this._getAllCommodityDict('FIN_PAYMENT_TYPE').filter(item => ![1, 2, 6].includes(+item.val))
    }

  },
  watch: {
    'updateDialogVisible'(val) {
      if (!val) {
        this.$refs.updateForm.clearValidate()
      } else {
        this.updateForm.accountPeriodTime = this.multipleSelection[0].accountPeriodTime
        this.time = this.multipleSelection[0].accountPeriodTime
      }
    }
  },
  created() {

  },
  mounted() {
    this._queryVendorList()
    this._getcargoownerList()
    this._queryStyleList()
    this.queryClick()
  },
  methods: {

    selectInit(row, index) {
      if (row.settlementStatus + '' === '9') {
        return false
      } else {
        return true
      }
    },
    updateDate() {
      if (this.multipleSelection.length === 1 && this.multipleSelection[0].settlementStatus + '' === '1') {
        this.updateDialogVisible = true
      } else {
        this.$message.warning('请勾选一条待结算状态单据调整日期')
      }
    },
    async handleSettlement() {
      const flag = this.multipleSelection.every(item => item.reconcileStatus + '' === '4' && item.settlementStatus + '' === '1' && item.paymentAmount)
      if (this.multipleSelection.length > 0 && flag) {
        try {
          this.settleLoading = true
          const arr = []
          this.multipleSelection.map(item => arr.push(item.estimationPayableCode))
          const { code, msg } = await settlement({ estimationPayableCode: String(arr) })
          if (code === 0) {
            this.$notify({
              title: msg,
              message: msg,
              type: 'success'
            })
            this.queryClick()
          }
        } finally {
          this.settleLoading = false
        }
      } else {
        this.$message.warning('请勾选已对账、待结算状态,且存在付款金额的单据发起结算')
      }
    },
    handleDiff() {
      if (this.multipleSelection.length !== 1 || this.multipleSelection[0].reconcileStatus + '' !== '3') {
        this.$message.warning('请选择一条【差异确认中】数据')
        return
      } else {
        this.showDialog(this.multipleSelection[0], 'diff')
      }
    },
    async _queryStyleList() {
      const { datas } = await queryStyleList()
      this.styleOptions = datas
    },
    getSummaries(param) {
      const { columns, data } = param || {}
      const sums = []
      const arr = ['totalPrice', 'paymentAmount', 'purchasePairs', 'purchaseAmount', 'surplusPairs', 'surplusAmount']
      columns && columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        if (arr.includes(column.property)) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            const num = Number(values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0))
            sums[index] = (num + '').indexOf('.') === -1 ? num : num.toFixed(2)
          } else {
            sums[index] = ''
          }
        }
      })
      return sums
    },
    getlabelArr(a, b) {
      const labelArr = []
      a.map((item, index) => {
        b.map((e, i) => {
          if (i === index) {
            labelArr.push({ label: item, value: this.row[e] })
          }
        })
      })
      return labelArr
    },
    _updateAccountPeriodTime() {
      this.$refs.updateForm.validate(async valid => {
        if (valid) {
          try {
            this.updateLoading = true
            const params = Object.assign({ id: this.multipleSelection[0].id }, this.updateForm)
            const { code, msg } = await updateAccountPeriodTime(params)
            if (code === 0) {
              this.$notify({
                title: msg,
                message: msg,
                type: 'success'
              })
              this.updateDialogVisible = false
              this.handleQuery()
            }
          } finally {
            this.updateLoading = false
          }
        }
      })
    },
    queryClick() {
      this.activeName === 'first' ? this.pager1.current = 1 : this.pager2.current = 1
      this.handleQuery(this.queryParams)
    },
    handleQuery() {
      this.queryLoading = true
      this.tableLoading = true
      if (this.activeName === 'first') {
        this.$refs.form.validate(async valid => {
          if (valid) {
            try {
              const { code, datas: { pager, records }} = await outBillPage(this.queryParams)
              if (code === 0) {
                this.pager1 = pager
                this.haveBillData = records.map(item => { return { ...item, loading: false } })
              }
            } finally {
              this.tableLoading = false
              this.queryLoading = false
            }
          }
        })
      } else {
        this.$refs.noForm.validate(async valid => {
          if (valid) {
            try {
              const { code, datas: { pager, records }} = await notOutBillPage(this.queryParams)
              if (code === 0) {
                this.pager2 = pager
                this.NoBillData = records.map(item => { return { ...item, loading: false } })
              }
            } finally {
              this.tableLoading = false
              this.queryLoading = false
            }
          }
        })
      }
    },
    async _getcargoownerList() {
      const { datas } = await getcargoownerList()
      this.cargoOwnerNameList = datas
    },
    pagerUpdate(val) {
      this.pager1 = val
      this.handleQuery(this.queryParams)
    },
    pagerUpdateNoForm(val) {
      this.pager2 = val
      this.handleQuery(this.queryParams)
    },
    async showDialgOprate(tag) {
      if (tag === 'out') {
        const { code, datas } = await shippingPaymentView(this.noForm)
        if (code === 0) {
          this.row = Object.assign({}, { title: '出货付款', paydialogData: datas })
        }
      } else {
        // 特殊付款
        if (this.multipleSelection.length === 0) {
          this.$message.warning('请勾选PO单')
          return
        } else {
          const purchaseOrderCodes = []
          this.multipleSelection.map(item => purchaseOrderCodes.push(item.purchaseOrderCode))
          const { code, error } = await specialPaymentValid({ purchaseOrderCodes: purchaseOrderCodes.join(',') })
          if (code === 0) {
            this.multipleSelection = this.multipleSelection.map(item => { return { ...item, requestFundsAmount: item.requestFundsAmount || '' } })
            this.row = Object.assign({}, { title: '特殊付款', paydialogData: this.multipleSelection, receiveAccount: '' })
          } else {
            this.$message.warning(error)
          }
        }
      }
      this.paydialogVisible = true
    },
    resetClick() {
      this.activeName === 'first' ? this.form = this.$options.data.call(this).form : this.noForm = this.$options.data.call(this).noForm
      this.queryClick()
    },
    handleDetail({ reconcileStatus, vendorId, paymentType, cargoOwnerCode }) {
      // 赔偿对账
      if (paymentType === 11) {
        this.$router.push({
          path: 'indemnity-details',
          append: 'true',
          query: { vendorId, form: JSON.stringify(this.form) }
        })
      } else {
        this.$router.push({
          path: 'details',
          append: 'true',
          query: { id: vendorId, reconcileStatus, form: JSON.stringify(this.form) }
        })
      }
    },
    async showDialog(row, type) {
      // 赔偿单据详情
      if (row.paymentType === 11) {
        this.handleIndemnityDetail(row)
        return
      }
      try {
        this.$set(row, 'loading', true)
        if (type !== 'purchaseOrderCode') {
          const { datas, datas: { outBillDetailByCollectVOList = [], outBillDetailByReturnVOList = [], reasonReconcilList = [] }} = await outBillDetail(row.id)
          for (const i in datas) {
            if (i === 'currency') {
              datas[i] = this.getAuditTypeLabel('CURRENCY_TYPE', datas[i])
            }
          }
          this.row = datas
          this.row.diffAmount = 0
          this.row.finalAmount = 0
          if (outBillDetailByCollectVOList) {
            outBillDetailByCollectVOList.map(item => {
              this.row.diffAmount = FloatAdd(this.row.diffAmount, item.reconcileAmount)
              this.$set(item, 'specification', `${item.style}/${item.color}/${item.size}`)
            })
            this.row.finalAmount = Number(FloatSub(this.row.paymentAmount, this.row.diffAmount))
          }
          if (outBillDetailByReturnVOList) {
            outBillDetailByReturnVOList.map(item => {
              this.row.diffAmount = FloatAdd(this.row.diffAmount, item.returnAmount)
              this.$set(item, 'specification', `${item.style}/${item.color}/${item.size}`)
              this.$set(item, 'reconcileAmount', item.returnAmount)
            })
            this.row.finalAmount = Number(FloatSub(this.row.paymentAmount, this.row.diffAmount))
          }
          if (type === 'sourceCode') {
            const dialogLabel = [
              { value: [7, 8, 9, 10], label: this.getlabelArr(labelDiff, valueDiff) },
              { value: [3], label: this.getlabelArr(reconcileLabel, reconcileValue) },
              { value: [5], label: this.getlabelArr(returnLabel, returnValue) }
            ]
            // souorceType为2代表退货，为1代表收货
            this.row = Object.assign(row, {
              type,
              title: this.dialogTitle.find(item => item.value === row.paymentType)?.label || '供应商对账单详情',
              labelArr: dialogLabel.find(item => item.value.includes(row.paymentType))?.label || [],
              columns1: this.dialogColumns.find(item => item.value.includes(row.paymentType))?.columns || [],
              tableData1: [5, 8, 10].includes(row.paymentType) ? outBillDetailByReturnVOList || [] : outBillDetailByCollectVOList || []

            })
          } else if (type === 'diff') {
            this.row = Object.assign(row, {
              type, title: '供应商对账单差异确认', labelArr: this.getlabelArr(reconcileLabel, reconcileValue),
              tableData1: outBillDetailByCollectVOList.map(item => { return { ...item, specification: `${item.style}/${item.color}/${item.size}` } }),
              columns1: sourceCodeColumns1,
              reasonList: reasonReconcilList || []
            })
          }
        } else {
          const { code, datas } = await notOutBillDetail(row.purchaseOrderCode)
          if (code === 0) {
            this.row = row
            this.row = Object.assign({
              type, title: '采购单详情', labelArr: this.getlabelArr(poLabel, poValue),
              tableData1: datas.detailList || [],
              columns1: sourceCodeColumns3
            })
          }
        }
        this.dialogVisible = true
      } finally {
        this.$set(row, 'loading', false)
      }
    },
    handleClick(val) {
      if (val.name === 'first') {
        this.$refs.NoBillDataRef.clearSelection()
      } else {
        this.$refs.haveBillDataRef.clearSelection()
      }
      this.queryClick()
    },
    // 供应商
    async _queryVendorList() {
      const { datas } = await queryVendorList({ vendorType: 4 })
      this.vendorOptions = datas
    },
    selectionChange(val) {
      this.multipleSelection = val
    },
    async handleIndemnityDetail(row) {
      try {
        this.$set(row, 'loading', true)
        const { datas } = await indemnityOutBillView({ id: row.id })
        this.row = datas
        this.resDialogVisible = true
      } finally {
        this.$set(row, 'loading', false)
      }
    }
  }
}
</script>

<style scoped lang="scss">
/* /deep/.el-input__inner{
    width: 140px!important;
} */
/deep/.el-dialog__title {
  font-weight: 500;
  font-family: normal;
  letter-spacing: 1px;
  font-size: 20px;
}
</style>
