<template>
  <div>
    <!-- 出货付款&&特殊付款弹框 -->
    <el-dialog
      :title="row.title"
      :visible.sync="dialogVisible"
      width="90%"
      center
      :close-on-click-modal="false"
    >
      <el-form ref="form" :model="row">
        <el-table
          ref="uploadTableDataRef"
          :data="row.paydialogData"
          :header-cell-style="{ background: '#fafafa' }"
          tooltip-effect="dark"
          max-height="500px"
          show-summary
          :summary-method="getSummaries"
          @selection-change="selectionChange"
        >
          <el-table-column v-if="row.title!=='特殊付款'" key="0" type="selection" width="60" align="center" />
          <el-table-column :label="$t('page.No')" type="index" width="60" align="center" />
          <el-table-column prop="purchaseOrderCode" label="采购单号" />
          <el-table-column prop="purchaseAmount" label="采购金额" />
          <template v-if="row.title==='出货付款'">
            <el-table-column key="1" prop="style" label="Style" />
            <el-table-column key="2" prop="purchasePairs" label="采购数量" />
            <el-table-column key="3" prop="shippingPaymentRatio" label="出货付款比例%" />
            <el-table-column key="4" prop="preparePayableAmount" label="预付应付金额" />
            <el-table-column key="5" prop="expectDeliveryTime" label="预计送货时间" width="280">
              <template slot-scope="scope">
                <el-date-picker
                  v-model="scope.row.expectDeliveryTime"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择时间"
                />
              </template>
            </el-table-column>
          </template>
          <template v-else>
            <el-table-column key="6" prop="currency" label="币种">
              <template slot-scope="scope">
                {{ getAuditTypeLabel('CURRENCY_TYPE', scope.row.currency) }}
              </template>
            </el-table-column>
            <el-table-column key="7" prop="requestFundsAmount" label="请款金额">
              <template slot-scope="scope">
                <el-form-item
                  :prop="'paydialogData.' + scope.$index + '.requestFundsAmount'"
                  :rules="rules.requestFundsAmount"
                >
                  <el-input v-model="scope.row.requestFundsAmount" @blur="scope.row.requestFundsAmount=blur(scope.row)" />
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column key="8" prop="receiveAccount" label="收款账户">
              <template slot-scope="scope">
                <el-form-item
                  :prop="'paydialogData.' + scope.$index + '.receiveAccount'"
                  :rules="rules.receiveAccount"
                >
                  <el-select ref="accountForm" v-model="scope.row.receiveAccount" :disabled="AccountList.length <= 1" placeholder="请选择" filterable @change="isChange">
                    <el-option
                      v-for="item in AccountList"
                      :key="item.id"
                      :label="item.cardNo"
                      :value="item.cardNo"
                    />
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
          </template>
        </el-table>
        <el-row v-if="row.title==='特殊付款'" class="mt-3">
          <el-col :span="2" style="height:36px;line-height:36px;">备注：</el-col><el-col :span="12"><el-input v-model="remark" style="width:400px;" /></el-col>
          <!-- <el-col :span="10">
            <el-form-item label="收款账户" prop="receiveAccount" :rules=" [{ required: true, message: '必填', trigger: 'change' }]">
              <el-select ref="accountForm" v-model="row.receiveAccount" :disabled="AccountList.length <= 1" placeholder="请选择" filterable @change="isChange">
                <el-option
                  v-for="item in AccountList"
                  :key="item.id"
                  :label="item.cardNo"
                  :value="item.cardNo"
                />
              </el-select>
            </el-form-item>
          </el-col> -->
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">关闭</el-button>
        <el-button v-if="row.title==='出货付款'" type="primary" :loading="loading" @click="handlePayable">生成预付应付</el-button>
        <el-button v-else type="primary" :loading="loading" @click="refunds">请款</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commodityInfoDict from '@/mixin/commodityInfoDict.js'

import { getVendorAccountListByVendorId } from '@/api/blurbInformation'
import { shippingPaymentToPreparePayableOrder, specialPaymentSubmit } from '@/api/vendorPurchase-api.js'
export default {

  mixins: [commodityInfoDict],

  props: {
    value: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      default: () => {}
    },
    form: {
      type: Object,
      default: () => {}
    }

  },
  data() {
    const validateFundsAmount = (rule, value, callback) => {
      // const reg = /^(([1-9]{1}\d*)|(0{1}))(\.\d{0,2})$/
      const reg = /^\d+.?\d{0,2}$/
      if (!value || value === 0) {
        callback(new Error('必填'))
      } else if (!reg.test(value)) {
        callback(new Error('格式错误'))
      } else {
        callback()
      }
    }
    return {
      AccountList: [],
      remark: '',
      loading: false,
      rules: {
        // receiveAccount: [{ required: true, message: '必填', trigger: 'change' }],
        requestFundsAmount: [{ required: true, message: '必填', trigger: 'blur' }, { validator: validateFundsAmount, trigger: 'blur' }],
        receiveAccount: [{ required: true, message: '必填', trigger: 'change' }]
      },
      pager: {
        current: 1,
        size: 20,
        total: 0
      },
      multipleSelection: []
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    specialPaymentSubmitParams() {
      return Object.assign({}, { list: this.row.paydialogData.map(item => { return { ...item, requestFundsAmount: Number(item.requestFundsAmount) } }), remark: this.remark })
    }
  },
  watch: {
    'dialogVisible'(val) {
      if (val) {
        console.log(this.row)
        this.row.title === '特殊付款' && this._getVendorAccountListByCargoOwnerCode(true)
        this.$refs.form && this.$refs.form.clearValidate()
      }
      if (!val) {
        this.$refs.form.resetFields()
        this.$refs.form.clearValidate()
      }
    }
  },
  created() {

  },
  mounted() {
    // this.row.pager ? this.pager = this.row.pager : ''
  },
  methods: {
    isChange(val) {
      console.log(val)
    },
    async _getVendorAccountListByCargoOwnerCode(flag) {
      if (flag) {
        const vendorIdList = []
        this.row.paydialogData.map(e => {
          vendorIdList.push(e.vendorId)
        })
        const { datas } = await getVendorAccountListByVendorId(vendorIdList)
        this.AccountList = datas
        this.row.paydialogData.map(e => {
          this.$set(e, 'receiveAccount', datas.length > 0 ? datas[0].cardNo : '')
        })
      }
    },
    /* inputQK(row) {
      const arr = JSON.parse(sessionStorage.getItem('special')) || []
      if (arr.length) {
        if (arr.find(item => item.id === row.id)) {
          arr.find(item => item.id === row.id).requestFundsAmount = row.requestFundsAmount
          sessionStorage.setItem('special', JSON.stringify(arr))
        }
      } */
    blur({ requestFundsAmount, purchaseAmount }) {
      if (requestFundsAmount) {
        if (requestFundsAmount > purchaseAmount) {
          this.$message.warning('请款金额不能大于采购金额')
          return ''
        }
        if ((requestFundsAmount + '').indexOf('.') !== -1) {
          return Number(requestFundsAmount).toFixed(2)
        } else {
          return requestFundsAmount
        }
      }
    },
    refunds() {
      // console.log(this.row.paydialogData)
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            this.loading = true
            const { code, msg } = await specialPaymentSubmit(this.specialPaymentSubmitParams)
            if (code === 0) {
              this.$notify({
                title: msg,
                message: msg,
                type: 'success'
              })
              this.$emit('input', false)
              this.$emit('refresh')
            }
          } finally {
            this.loading = false
          }
        }
      })
    },
    selectionChange(val) {
      this.multipleSelection = val
    },
    /*   async pagerUpdate(val) {
      this.pager = val
      const params = Object.assign({}, this.pager, this.form)
      const { code, datas: { pager, records }} = await shippingPaymentView(params)
      if (code === 0) {
        this.pager = pager
        this.row.paydialogData = records
      }
    }, */
    async handlePayable() {
      const flag = this.multipleSelection.every(item => item.expectDeliveryTime)
      if (this.multipleSelection.length > 0 && flag) {
        try {
          this.loading = true
          const { code, msg } = await shippingPaymentToPreparePayableOrder(this.multipleSelection)
          if (code === 0) {
            this.$notify({
              title: msg,
              message: msg,
              type: 'success'
            })
            this.$emit('input', false)
            this.$emit('refresh')
          }
        } finally {
          this.loading = false
        }
      } else {
        this.$message.warning('请填写【预计送货时间】 选择PO单')
      }
    },
    getSummaries(param) {
      const { columns, data } = param || {}
      const sums = []
      const arr = ['requestFundsAmount', 'purchaseAmount', 'purchasePairs', 'preparePayableAmount']
      columns && columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        if (arr.includes(column.property)) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            const num = Number(values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0))
            sums[index] = (num + '').indexOf('.') === -1 ? num : num.toFixed(2)
          } else {
            sums[index] = ''
          }
        }
      })
      return sums
    } }
}

</script>

<style scoped lang="scss">
 /deep/.el-table .cell {
   /*  height:38px!important;
    line-height: 38px!important; */
      .el-form-item {
    margin-bottom: 0px;
}
}
</style>
