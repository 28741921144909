<template>
  <div>
    <el-dialog title="供应商赔偿单据详情" :visible.sync="resDialogVisible" width="95%" center :close-on-click-modal="false">
      <!-- 赔偿单据详情 -->
      <div v-if="row.indemnityBillViewDTO">
        <el-row justify="space-between" class="mb-3">
          <el-col :span="12">暂估单号：{{ row.indemnityBillViewDTO.estimationPayableCode }}</el-col>
          <el-col :span="12" style="text-align: right">生效时间：{{ row.indemnityBillViewDTO.effectiveTime }}</el-col>
        </el-row>
        <Table
          :table-data="tableData"
          :columns="restitutionColumns"
        />
      </div>
      <!-- 赔偿单对账信息 -->
      <div v-if="row.outBillViewDTO">
        <div class="el-dialog__header" style="text-align: center;margin-bottom:25px;">
          <span class="el-dialog__title">赔偿单对账信息</span>
        </div>
        <el-row>
          <el-col :span="5">暂估单号：{{ row.outBillViewDTO.estimationPayableCode }}</el-col>
          <el-col :span="5">对账人：{{ row.outBillViewDTO.reconcileUserName }}</el-col>
          <el-col :span="5">对账时间：{{ row.outBillViewDTO.reconcileTime }}</el-col>
          <el-col :span="5">对账状态：{{ row.outBillViewDTO.reconcileStatusI18 }}</el-col>
        </el-row>
        <el-row>
          <el-col :span="5">录入金额：{{ row.outBillViewDTO.inputAmount }}</el-col>
          <el-col :span="5">录入币种：{{ row.outBillViewDTO.inputCurrency }}</el-col>
          <el-col :span="5">对账金额：{{ row.outBillViewDTO.totalPrice }}</el-col>
          <el-col :span="5">结算币种：{{ row.outBillViewDTO.currency }}</el-col>
        </el-row>
        <el-row class="mb-3">
          <el-col :span="5">结算汇率：{{ row.outBillViewDTO.settlementRate }}</el-col>
          <el-col :span="5">是否开票：{{ row.outBillViewDTO.ifInvoiceI18 === 'null' ? '' : row.outBillViewDTO.ifInvoiceI18 }}</el-col>
          <el-col :span="5">开票金额：{{ row.outBillViewDTO.invoiceAmount }}</el-col>
          <el-col :span="4">不开票金额：{{ row.outBillViewDTO.noInvoiceAmount }}</el-col>
        </el-row>
        <Table
          :table-data="row.outBillDetailIndemnityViewDTOList || []"
          :columns="billColumns"
          :show-summary="true"
          :get-summaries="getSummaries"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="resDialogVisible = false">关闭</el-button>
      </span>
    </el-dialog>
    <ShowPhoto v-model="photoVisible" :url="url" />
  </div>
</template>
<script>
import Table from '@/components/Table'
import ShowPhoto from '@/components/ImageView'
export default {
  components: { Table, ShowPhoto },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      restitutionColumns: [
        { label: '赔偿单号', prop: 'indemnityBillCode' },
        { label: '供应商', prop: 'vendorName' },
        { label: '赔偿类型', prop: 'indemnityTypeName' },
        { label: '业务类型', prop: 'businessTypeName' },
        { label: '赔偿原因', prop: 'indemnityReasonName' },
        { label: '币种', prop: 'currencyCode' },
        { label: '金额', prop: 'indemnityAmount' },
        { label: '创建人', prop: 'billCreateUserName' },
        { label: '创建时间', prop: 'billCreateTime' },
        {
          label: '供应商确认邮件截图',
          prop: 'vendorConfirmMailUrl',
          formatter: (row) => {
            const regex = /(.jpg|.png|.gig|.jpeg|.bmp)/
            if (regex.test(row.vendorConfirmMailUrl.toLowerCase())) {
              return <img src={row.vendorConfirmMailUrl} onClick={() => this.handleImgClick(row.vendorConfirmMailUrl)} style='width: 100%;cursor:pointer' />
            } else {
              return <el-button type='text'>
                <a href={row.vendorConfirmMailUrl} target='_blank'>下载截图文件</a>
              </el-button>
            }
          }
        },
        {
          label: '赔偿明细文件',
          prop: 'indemnityDetailFileUrl',
          formatter: (row) => {
            return <el-button type='text'>
              <a href={row.indemnityDetailFileUrl} target='_blank'>下载附件文件</a>
            </el-button>
          }
        }
      ],
      billColumns: [
        { label: '赔偿单号', prop: 'indemnityBillCode' },
        { label: '采购订单', prop: 'purchaseOrderCode' },
        { label: '抵扣金额', prop: 'offsetAmount' }
      ],
      photoVisible: false,
      url: ''
    }
  },
  computed: {
    resDialogVisible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    tableData() {
      if (this.row.indemnityBillViewDTO) {
        return [this.row.indemnityBillViewDTO]
      } else {
        return []
      }
    }
  },
  methods: {
    getSummaries(param) {
      const { columns, data } = param || {}
      const sums = []
      const arr = ['offsetAmount']
      columns && columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        if (arr.includes(column.property)) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            const num = Number(values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0))
            sums[index] = (num + '').indexOf('.') === -1 ? num : num.toFixed(2)
          } else {
            sums[index] = ''
          }
        }
      })
      return sums
    },
    handleImgClick(url) {
      if (url) {
        this.photoVisible = true
        this.url = url
      }
    }
  }
}
</script>

