<template>
  <div>
    <el-dialog :title="row.title" :visible.sync="dialogVisible" width="95%" center :close-on-click-modal="false">
      <el-row class="mb-3">
        <el-col v-for="(item, index) in row.labelArr" :key="index" :span="8">{{ item.label }}：{{ item.value }}</el-col>
      </el-row>
      <Table
        :table-data="row.tableData1"
        :columns="row.columns1"
        :cell-style="row.type === 'diff' ? cellStyle : () => { }"
        :show-summary="true"
        :get-summaries="getSummaries"
      />
      <div v-if="row.type === 'diff' && row.reasonList && row.reasonList.length">
        <el-divider content-position="left">差异提交历史</el-divider>
        <div class="list mb-3">
          <label>驳回备注</label>
          <div class="list-item">
            <p v-for="item of rejectNote" :key="item.id" class="settlement-management-reconciliation-management--mark">
              <span class="ml-4 mark-item-reason">{{ item.reasonText }}</span>
              <span class="ml-4 mark-item-time"> {{ item.createTime }} </span>
            </p>
          </div>
        </div>
        <div class="list mb-3">
          <label>差异原因</label>
          <div class="list-item">
            <p v-for="item of reasonList" :key="item.id" class="settlement-management-reconciliation-management--mark">
              <span class="ml-4 mark-item-reason">{{ item.reasonText }}</span>
              <span class="ml-4 mark-item-time"> {{ item.createTime }} </span>
            </p>
          </div>

        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">关闭</el-button>
        <el-button
          v-if="row.type === 'diff'"
          type="primary"
          :loading="reconcileDiffRejectLoading"
          @click="_reconcileDiffReject"
        >差异驳回</el-button>
        <el-button
          v-if="row.type === 'diff'"
          type="primary"
          :loading="reconcileDiffSureLoading"
          @click="_reconcileDiffSure"
        >确认差异</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Table from '@/components/Table'
import { reconcileDiffReject, reconcileDiffConfirm, reconcileDiffExport } from '@/api/vendorPurchase-api'
export default {
  components: { Table },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      default: () => { }
    }

  },
  data() {
    return {
      reconcileDiffRejectLoading: false,
      reconcileDiffSureLoading: false,
      exportLoading: false
    }
  },
  computed: {
    rejectNote() {
      if (this.row.type === 'diff' && this.row.reasonList && this.row.reasonList.length) {
        return this.row.reasonList.filter(item => item.reasonType === 2)
      } else {
        return []
      }
    },
    reasonList() {
      if (this.row.type === 'diff' && this.row.reasonList && this.row.reasonList.length) {
        return this.row.reasonList.filter(item => item.reasonType === 1)
      } else {
        return []
      }
    },
    dialogVisible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    async handleExport() {
      try {
        this.exportLoading = true
        const { code, datas } = await reconcileDiffExport(this.row.id)
        if (code === 0) {
          window.open(datas, '_blank')
        }
      } finally {
        this.exportLoading = false
      }
    },
    _reconcileDiffSure() {
      this.$confirm('是否确定此对账单?', '提示', {
        confirmButtonText: '确定',
        showCancelButton: false,
        type: 'warning'
      }).then(async() => {
        try {
          this.reconcileDiffSureLoading = true
          const { code, msg } = await reconcileDiffConfirm({ id: this.row.id })
          if (code === 0) {
            this.$notify({
              title: msg,
              message: msg,
              type: 'success'
            })
            this.$emit('input', false)
            this.$emit('refresh')
          }
        } finally {
          this.reconcileDiffSureLoading = false
        }
      })
    },
    _reconcileDiffReject() {
      this.$prompt('', '填写差异驳回原因', {
        confirmButtonText: '确定',
        center: true,
        inputType: 'textarea',
        cancelButtonText: '取消',
        inputPattern: /\S/,
        inputErrorMessage: '请填写差异驳回原因',
        inputValidator: (val) => {
          if (val && val.length > 50) {
            return '差异驳回原因不能大于50个字符'
          }
        }
      }).then(async({ value: reasonText }) => {
        try {
          this.reconcileDiffRejectLoading = true
          const { estimationPayableCode, id, purchaseOrderCode } = this.row
          const { code, msg } = await reconcileDiffReject({ estimationPayableCode, id, purchaseOrderCode, reasonText })
          if (code === 0) {
            this.$notify({
              title: msg,
              message: msg,
              type: 'success'
            })
            this.$emit('input', false)
            this.$emit('refresh')
          }
        } finally {
          this.reconcileDiffRejectLoading = false
        }
      })
    },
    getSummaries(param) {
      const { columns, data } = param || {}
      const sums = []
      const arr = ['amount', 'collectPairs', 'reconcilePairs', 'returnPairs', 'reconcileAmount', 'purchaseTotalPrice', 'surplusPairs', 'number', 'returnAmount']
      columns && columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        if (arr.includes(column.property)) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            const num = Number(values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0))
            sums[index] = (num + '').indexOf('.') === -1 ? num : num.toFixed(2)
          } else {
            sums[index] = ''
          }
        }
      })
      return sums
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 8 || columnIndex === 7) {
        if (row['unitPrice'] !== row['changeAfterHaveTaxPrice']) {
          return {
            background: '#EEEE00'
          }
        }
      } else if (columnIndex === 9 || columnIndex === 10) {
        if (row['reconcilePairs'] !== row['collectPairs']) {
          return {
            background: '#EEEE00'
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.list {
  display: flex;
  width: 100%;
}
.list-item {
  margin-left: 10px;
  flex: 1;
  max-height: 160px;
  overflow-y: scroll;
}
.settlement-management-reconciliation-management--mark {
    display: flex;
    justify-content: space-between;
    background: #f5f5f5;
    padding: 5px;
    border-radius: 5px;
    margin: 0px;
    margin-bottom: 10px;
    .mark-text {
      width: calc(100% - 12em)
    }
    .mark-time {
      width: 10em;
    }
  }
</style>
